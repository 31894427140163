.remove-link {
    text-decoration: none;
    color: white;
}

.remove-link:hover {
    color: rgb(148, 243, 24);
}

.toggle {
    border: none;
}

.toggle:focus {
    box-shadow: none;
}