.button_align {
    padding-top: 20px
}

.submit-btn-style {
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 30px;
    cursor: pointer;
}
