.center {
    display: grid;
    place-items: center;
}

.sub-btn {
    width: 200px;
    border-radius: 20px;
}

@media only screen and (max-width: 900px) {
    .width-box {
        width: 100%;
    }

    .box {
        margin-top: 50px;
        width: 90%;
        background-color: rgb(197, 233, 162);
        border-radius: 20px;
        padding: 20px;
        overflow:auto;
    }

    .box-content {
        word-wrap: break-word
    }
}

@media only screen and (min-width: 900px) {
    .width-box {
        width: 50%;
    }

    .box {
        margin-top: 50px;
        width: 80%;
        background-color: rgb(197, 233, 162);
        border-radius: 20px;
        padding: 20px;
        overflow:auto;
    }
    
    .box-content {
        word-wrap: break-word
    }
}

