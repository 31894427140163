.center-i {
    font-weight: 500;
    color: #364a63;
    display: block;
}

.table_heading {
    font-size: 13px;
    color: #8094ae;
}

.small-font {
    font-size: 12px;
}

.district_details {
    color: #364a63;
}

@media only screen and (max-width: 900px) {
    .big-screen {
        display: none;
    }
    .small-screen {
        display: block;
    }
  }

@media only screen and (min-width: 900px) {
    .small-screen {
        display: none;
    }
    .big-screen {
        display: block;
    }
  }

.fee-free {
    color: 'white'; 
    background-color: green;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.fee-paid {
    color: 'white'; 
    background-color: #e60000;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.green {
    color: 'white'; 
    background-color: green;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.table {
    clear: both;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
    border: 1px solid #c6ceda;
    border-radius: 5px;
}

.book {
    font-size: 0.75rem;
    font-weight: 900;
}

.not-link {
    color: white;

    text-decoration: none;
}
